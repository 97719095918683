/*eslint-disable */
import React, { useState } from "react";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import ckeditor, { CKEditor } from "@ckeditor/ckeditor5-react";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import inputFields from "../API/inputFields";
import { capitalize } from "../helpers/timeModule";
import _ from "lodash";
import Button from "@mui/material/Button";
import TagBadge from "./reusablecomponents/TagBadge";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

const ProjectForm = ({
  createNewProject,
  categories,
  sdgs,
  sdgError,
  sdgLoading,
  projectCategoriesError,
  projectCategoriesIsLoading,
  ProjectCreationMutation,
}) => {
  const [projectPrivacy, setProjectPrivacy] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectIdea, setProjectIdea] = useState("");
  const [projectPurpose, setProjectPurpose] = useState("");
  const [projectMission, setProjectMission] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectCategory, setProjectCategory] = useState("");
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [projectFields, setProjectFields] = useState(inputFields);
  const [projectImage, setProjectImage] = useState(null);
  const [projectStgs, setProjectStgs] = useState([]);

  const handleFieldChange = (e) => {
    let values = { ...projectFields };
    values[e.target.name] = e.target.checked;
    setProjectFields(values);
  };

  const handleStgsChange = (e) => {
    if (e.target.checked === true) {
      setProjectStgs([...projectStgs, e.target.value]);
      return;
    }

    if (e.target.checked === false) {
      setProjectStgs(projectStgs.filter((item) => item !== e.target.value));
      return;
    }
  };

  const displayCategories = () => {
    // -- loading ---
    if (projectCategoriesIsLoading) return "Loading categories....";
    // --- error
    if (projectCategoriesError !== null)
      return (
        <Alert variant="outlined" severity="error">
          Something went wrong while fetching the categories...
        </Alert>
      );
    // --- results
    let results = categories.map((value, index) => {
      return (
        <option key={`cat${index}`} value={value.id}>
          {value.name}
        </option>
      );
    });
    return results;
  };

  const displaySdgsFields = () => {
    // --- ERROR WHILE FETCHING THE SDGS ----
    if (sdgError !== null)
      return (
        <Alert variant="outlined" severity="error">
          Something went wrong while fetching the sdgs...
        </Alert>
      );

    // --- LOADING THE SDGS -----
    if (sdgLoading) return <CircularProgress color="secondary" />;

    //  ---- SDGS GOTTEN ----
    return sdgs.map((item) => {
      return (
        <div key={item.name} className="m-2">
          <label>{capitalize(item.name)}</label>&nbsp;
          <input
            type="checkbox"
            name={item.name}
            defaultChecked={inputFields[item.name]}
            onChange={(e) => handleStgsChange(e)}
            value={item.id}
          />
        </div>
      );
    });
  };

  const displayInputFields = () => {
    return Object.keys(projectFields).map((key) => {
      return (
        <div key={key} className="m-2">
          <label>{capitalize(key)}</label>&nbsp;
          {key !== "summary" ? (
            <input
              type="checkbox"
              name={key}
              defaultChecked={inputFields[key]}
              onChange={(e) => handleFieldChange(e)}
            />
          ) : (
            <input
              type="checkbox"
              name={key}
              defaultChecked={inputFields[key]}
              onChange={(e) => handleFieldChange(e)}
              disabled
            />
          )}
        </div>
      );
    });
  };

  const handleAddTag = () => {
    if (tag == "") {
      toast.warning("You cannot add an empty tag");
    } else {
      setTags((prevTags) => [...prevTags, tag]);
      setTag("");
    }
  };

  const formReset = () => {
    setProjectPrivacy(false);
    setProjectName("");
    setProjectIdea("");
    setProjectDescription("");
    setProjectPurpose("");
    setProjectMission("");
    setTag("");
    setTags([]);
    setProjectImage(null);
    setProjectStgs([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let selectionValues = _.uniq(projectStgs);
    let formData = new FormData();

    formData.append("project[name]", projectName);
    formData.append(
      "project[project_category_id]",
      projectCategory === "" ? "1" : projectCategory
    );
    formData.append("project[idea]", projectIdea);
    formData.append("project[purpose]", projectPurpose);
    formData.append("project[description]", projectDescription);
    formData.append("project[mission]", projectMission);
    formData.append("project[input_fields]", JSON.stringify(projectFields));
    formData.append("project[tags]", JSON.stringify(tags));
    formData.append("project[project_stgs]", JSON.stringify(selectionValues));
    formData.append("project[is_private]", projectPrivacy);
    if (projectImage !== null) {
      formData.append("project[image]", projectImage);
    }
    // ==== CREATE THE PROJECT ====
    createNewProject(formData);
    formReset();
  };

  return (
    <div className="tt-project-form-area">
      <form>
        <div className="form-group m-3 row">
          <div className="add-image-projects">
            <label>Add image:</label>
            <input
              type="file"
              className="form-control"
              name="idea"
              onChange={(e) => setProjectImage(e.target.files[0])}
              value={projectImage}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <label>Project Name:</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setProjectName(e.target.value)}
              value={projectName}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <label>Project Category:</label>
            <select
              id="inputCategory"
              onChange={(e) => setProjectCategory(e.target.value)}
              placeholder="Select a person"
              className="form-control"
              name="category"
            >
              {displayCategories()}
            </select>
          </div>
        </div>

        <div className="form-group  row">
          <div className="col-12">
            <label>Vision Statement:</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={projectIdea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setProjectIdea(data);
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label>Mission Statement:</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={projectMission}
              onChange={(event, editor) => {
                const data = editor.getData();
                setProjectMission(data);
              }}
            />
          </div>
        </div>

        <div className="form-group  row">
          <div className="col-12">
            <label>Purpose Statement:</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={projectPurpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setProjectPurpose(data);
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label>Project Description:</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={projectDescription}
              onChange={(event, editor) => {
                const data = editor.getData();
                setProjectDescription(data);
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <h3>Select Fields</h3>
            <div className="input-fields">{displayInputFields()}</div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <h3>Select Associated SDG's</h3>
            <div className="input-fields">{displaySdgsFields()}</div>
          </div>
        </div>
        <div className="form-group">
          <label>Do you want to make this project private?</label>
          &nbsp;&nbsp;
          <input
            type="checkbox"
            name="privacy"
            defaultChecked={projectPrivacy}
            onChange={(e) => setProjectPrivacy(e.target.checked)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">Tags</label>
          {_.isEmpty(tags) ? (
            <div className="p-2 text-muted">
              <span>Add tags and they will appear here</span>
            </div>
          ) : (
            <>
              <div className="tt-tags-display">
                {tags.map((tag, idx) => (
                  <TagBadge key={idx} tag={`#${tag}`} />
                ))}
              </div>
            </>
          )}
          <div className="d-flex">
            <input
              className="form-control"
              type="text"
              placeholder="Add tag here"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
            <button
              className="btn btn-success mv-sg"
              type="button"
              onClick={handleAddTag}
            >
              +
            </button>
          </div>
        </div>
      </form>
      <div>
        <div className="text-center">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disabled={ProjectCreationMutation.isLoading ? true : false}
            startIcon={<CreateNewFolderIcon />}
            onClick={handleSubmit}
          >
            {ProjectCreationMutation.isLoading
              ? "Creating a project...."
              : " Create Project"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectForm;
/*eslint-enable no-unused-vars */
